import { lazy, useEffect } from 'react';
import { Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

import { MyFavorites } from '@hub/pages/MyFavorites';

const Details = lazy(() => import('@hub/pages/Details'));
const Offers = lazy(() => import('@hub/pages/Offers'));
const MatchMaker = lazy(() => import('@hub/pages/MatchMaker'));
const FullFunnelRedirect = lazy(() => import('@hub/pages/FullFunnelRedirect'));
const SuccessFormFlow = lazy(
  () => import('@hub/pages/MatchMaker/SuccessFormFlow')
);

export function Router() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!pathname.includes('/ofertas/')) return;
    const offerSlug = pathname.split('/').pop();
    if (!offerSlug) return;

    return navigate(RouteMap.ProductDetail.replace(':slug', offerSlug));
  }, [pathname]);

  return (
    <>
      <Route path={RouteMap.Offer} element={<Offers />} />
      <Route path={RouteMap.ProductDetail} element={<Details />} />
      <Route path={RouteMap.MatchMaker} element={<MatchMaker />} />
      <Route path={RouteMap.MatchMakerResult} element={<SuccessFormFlow />} />
      <Route
        path={RouteMap.FullFunnelRedirect}
        element={<FullFunnelRedirect />}
      />
      <Route
        path={RouteMap.MyFavorites}
        element={
          window.isNativeApp && window.NativeFeatures?.NotificationSchedule ? (
            <MyFavorites />
          ) : (
            <Navigate to={RouteMap.Dashboard} />
          )
        }
      />
    </>
  );
}
