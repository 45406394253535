import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from '@common/hooks/useTracking';
import classNames from 'classnames';

import { Text } from '@consumidor-positivo/aurora';

import './styles.scss';

type NavButton = {
  label: string;
  path: string;
  icon: JSX.Element;
};

type AppNavigationProps = {
  buttons: NavButton[];
};

export const AppNavigation: React.FC<AppNavigationProps> = ({ buttons }) => {
  const { elementClickedEvent } = useTracking();
  const { isDesktop } = useDeviceSize();
  const location = useLocation();
  const navigate = useNavigate();

  const paths = buttons.map(({ path }) => path);

  const handleAppNavigationClick = (path: string) => {
    elementClickedEvent({
      elementType: 'button',
      location: location.pathname,
      position: 'app-navigation',
      name: path,
    });

    navigate(path);
  };

  if (!paths.includes(location.pathname) || isDesktop) {
    return null;
  }

  return (
    <div className="app-navigation">
      <nav className="app-navigation__nav">
        {buttons.map(({ label, path, icon }) => {
          const isActive = location.pathname === path;
          return (
            <div
              key={path}
              className={classNames('app-navigation__action', {
                'app-navigation__action--active': isActive,
              })}
              onClick={() => handleAppNavigationClick(path)}
            >
              <div
                className={classNames('app-navigation__indicator', {
                  'app-navigation__indicator--active': isActive,
                })}
              />
              {icon}
              <Text as="p" variant="caption">
                {label}
              </Text>
            </div>
          );
        })}
      </nav>
    </div>
  );
};
