import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Field, Form } from 'houseform';
import { z } from 'zod';

import { useUserContext } from '@common/contexts/UserContext';
import { useTracking } from '@common/hooks/useTracking';
import { handleErrorLog } from '@common/utils/handleErrorLogs';

// Components
import { Button, TextareaField } from 'iq-blueberry';
import { Text } from '@consumidor-positivo/aurora';
import { Modal } from '@common/components/Modal';

// Assets
import starIcon from '@common/assets/icon/star.svg';
import heartIcon from '@common/assets/icon/heart.svg';
import checkIcon from '@common/assets/icon/check.svg';

import './styles.scss';

type AppReviewProps = {
  className?: string;
};

type FormType = {
  comment: string;
};

function SendIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.638.862c.26.26.26.682 0 .943L8.305 9.138a.667.667 0 11-.943-.943L14.695.862c.26-.26.683-.26.943 0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.638.862c.181.18.243.45.158.691l-4.667 13.334a.666.666 0 01-1.238.05L7.328 9.172 1.563 6.609a.667.667 0 01.05-1.238L14.947.704a.667.667 0 01.691.158zM3.641 6.074l4.463 1.983c.151.067.272.188.339.339l1.983 4.463L14.08 2.42 3.64 6.075z"
        fill="#fff"
      />
    </svg>
  );
}

export const AppReview = ({ className }: AppReviewProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const reviewAppRef = useRef<HTMLDivElement>(null);

  const { user, getUser } = useUserContext();
  const {
    elementClickedEvent,
    elementViewedEvent,
    formStartedEvent,
    formSubmittedEvent,
    formErroredEvent,
  } = useTracking('app-review');

  useEffect(() => {
    if (!reviewAppRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        elementViewedEvent({
          name: 'review-app-component',
          elementType: 'card',
          location: window.location.href,
        });
        observer.disconnect();
      });
    });
    observer.observe(reviewAppRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleReviewApp = async () => {
    setIsModalOpen(true);

    elementClickedEvent({
      name: 'review-app-opened',
      elementType: 'button',
      location: window.location.href,
    });
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setModalStep(1);

    elementClickedEvent({
      name: 'review-app-closed',
      elementType: 'button',
      location: window.location.href,
    });
  };

  const handleRedirectToStore = async () => {
    let redirectURL =
      'https://play.google.com/store/apps/details?id=br.com.foursys.boavistaconsumidor&hl=pt_BR&showAllReviews=true';

    const isIOS = window.navigator.userAgent.match(/iPhone|iPad|iPod/i);

    if (isIOS)
      redirectURL =
        'https://apps.apple.com/br/app/consumidor-positivo/id1482315306?action=write-review';

    setIsModalOpen(false);
    setModalStep(1);

    elementClickedEvent({
      name: 'review-app-redirect-to-store',
      text: isIOS ? 'App Store' : 'Play Store',
      elementType: 'button',
      location: window.location.href,
    });

    window.open(redirectURL, '_top');
  };

  const handleSubmit = async (data: FormType) => {
    const apiUrl = import.meta.env.VITE_ZENDESK_API;

    try {
      setIsLoading(true);
      const user = await getUser();

      const formData = new FormData();
      const payload = {
        subject: 'CP | Avaliação do Aplicativo',
        body: data.comment,
        customer_name: user?.full_name,
        customer_cpf: user?.document,
        customer_email: user?.email,
        workspace: 'consumidorpositivo',
      };

      Object.keys(payload).forEach((key) => {
        const typedKey = key as keyof typeof payload;
        formData.append(typedKey, `${payload[typedKey]}`);
      });

      await fetch(`${apiUrl}/v1/zendesk/ticket`, {
        method: 'POST',
        body: formData,
      });

      formSubmittedEvent(payload);
    } catch (error: any) {
      formErroredEvent({
        errorResponse: error,
        formStep: { stepName: 'review-app-submit', stepPosition: 1 },
      });
      handleErrorLog(error);
    } finally {
      setIsLoading(false);
      setModalStep(3);
    }
  };

  if (!window.isNativeApp) return null;

  return (
    <>
      <div ref={reviewAppRef} className={classNames(className, 'app-review')}>
        <img src={starIcon} alt="Icone de estrela" />
        <Text as="p" className="app-review__text">
          Como está sendo a <strong>sua experiência</strong> na Consumidor
          Positivo?
        </Text>
        <Button
          type="text"
          size="medium"
          color="default"
          expand="y"
          loading={false}
          disabled={false}
          onClick={handleReviewApp}
        >
          Avaliar aplicativo
        </Button>
      </div>

      {createPortal(
        <Modal
          className="app-review__modal"
          mobile="center"
          desktop="center"
          showModal={isModalOpen}
          onClose={onCloseModal}
        >
          <div className="app-review__modal__content">
            {modalStep === 1 && (
              <>
                <img
                  className="app-review__modal__icon"
                  src={heartIcon}
                  alt="Icone de coração"
                />
                <Text
                  className="app-review__modal__title"
                  as="h2"
                  variant="heading-small"
                >
                  O que você está achando do aplicativo?
                </Text>
                <Text
                  className="app-review__modal__description"
                  as="p"
                  variant="body-medium"
                >
                  Nós vamos adorar saber{' '}
                  <strong>como está sendo sua experiência :)</strong>
                </Text>

                <div className="app-review__modal__actions">
                  <Button
                    type="primary"
                    size="large"
                    color="default"
                    expand="x"
                    justify="center"
                    loading={false}
                    disabled={false}
                    onClick={handleRedirectToStore}
                  >
                    Estou gostando!
                  </Button>
                  <Button
                    type="secondary"
                    size="large"
                    color="default"
                    expand="x"
                    justify="center"
                    loading={false}
                    disabled={false}
                    onClick={() => {
                      setModalStep(2);
                      formStartedEvent();
                    }}
                  >
                    Pode melhorar
                  </Button>
                </div>
              </>
            )}

            {modalStep === 2 && (
              <>
                <Text
                  className="app-review__modal__title--second-step"
                  as="h2"
                  variant="heading-small"
                >
                  Conta pra gente o que você não gostou
                </Text>
                <Text
                  className="app-review__modal__description"
                  as="p"
                  variant="body-medium"
                >
                  Sua opinião é importante para melhorarmos nosso app e
                  serviços.
                </Text>

                <Form<FormType> onSubmit={handleSubmit}>
                  {({ submit }) => (
                    <div className="app-review__modal__form">
                      <Field
                        name="comment"
                        onSubmitValidate={z.string().min(1, {
                          message: 'Campo comentário é obrigatório',
                        })}
                      >
                        {({ value, setValue, onBlur, errors }) => (
                          <>
                            <TextareaField
                              label="Comentário"
                              placeholder="Escreva aqui..."
                              onChange={(value) => setValue(value)}
                              value={value}
                              onBlur={onBlur}
                            />
                            <Text
                              as="p"
                              variant="body-small"
                              className="app-review__modal__form__error"
                            >
                              {errors.map((error) => error).join('')}
                            </Text>
                          </>
                        )}
                      </Field>

                      <Button
                        onClick={submit}
                        type="primary"
                        size="large"
                        color="default"
                        expand="x"
                        justify="center"
                        Icon={SendIcon}
                        loading={isLoading}
                        disabled={false}
                      >
                        Enviar comentário
                      </Button>
                    </div>
                  )}
                </Form>
              </>
            )}

            {modalStep === 3 && (
              <>
                <div className="app-review__modal__icon--third-step">
                  <img src={checkIcon} alt="Icone de check" />
                </div>

                <Text
                  className="app-review__modal__title"
                  as="h2"
                  variant="heading-small"
                >
                  {`${user?.full_name}, recebemos seu comentário 🤩`}
                </Text>
                <Text
                  className="app-review__modal__description"
                  as="p"
                  variant="body-medium"
                >
                  Agradecemos por dedicar uma parte do seu tempo para nos ajudar
                  a melhorar.
                </Text>
                <Text
                  className="app-review__modal__description"
                  as="p"
                  variant="body-medium"
                >
                  A sua opinião é essencial para entendermos o que precisa ser
                  feito.
                </Text>

                <div className="app-review__modal__actions">
                  <Button
                    type="secondary"
                    size="large"
                    color="default"
                    expand="x"
                    justify="center"
                    loading={false}
                    disabled={false}
                    onClick={() => {
                      setIsModalOpen(false);

                      elementClickedEvent({
                        name: 'review-app-finished',
                        elementType: 'button',
                        location: window.location.href,
                      });
                    }}
                  >
                    Fechar
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};
