import { LoggedInHeaderV2 } from '@common/components/LoggedInHeader/LoggedInHeaderV2';
import { LoggedOutHeader } from '@common/components/LoggedOutHeader';
import { LoaderCircular } from '@common/components/LoaderCircular';
import { Conditional } from '@consumidor-positivo/aurora';
import { HubModal } from '@hub/components/HubModal';
import { Footer } from '@common/components/Footer';

import { useUserContext } from '@common/contexts/UserContext';
import { useSortingHat } from '@common/hooks/useSortingHat';
import { Suspense, useEffect } from 'react';

import { AppNavButtons } from '@common/pages/Dashboard/dataNavigation';
import { AppNavigation } from '@common/components/AppNavigation';
import { useDashboard } from '@common/pages/Dashboard/hook';

import 'iq-blueberry/src/core/components/legacy/FooterClassic.styl';
import { Outlet } from 'react-router-dom';

export const HomeDefaultTemplate = () => {
  const { user, getUser } = useUserContext();
  const { variantControl } = useDashboard();
  useSortingHat({ immediate: true, decideDelayInSeconds: 2 });

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Conditional
        condition={!!user}
        renderIf={<LoggedInHeaderV2 username={user?.full_name} />}
        renderElse={<LoggedOutHeader />}
      />

      <main className="private-default-template">
        <Suspense fallback={<LoaderCircular fullPage />}>
          <Outlet />
        </Suspense>

        <Conditional
          condition={variantControl.isAppNavigation}
          renderIf={<AppNavigation buttons={AppNavButtons} />}
        />
      </main>

      <HubModal />
      <Footer />
    </>
  );
};
