import { RouteMap } from '@common/constants/RouteMap';
import { useUserContext } from '@common/contexts/UserContext';
import { useTracking } from '@common/hooks/useTracking';
import {
  isUserFromWebView,
  redirectInApp,
} from '@common/utils/webviewComunication';
import { SHOffer } from '@libs/creditkit';
import { delay } from '@libs/utils/helpers/delay';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

export function useOffer({
  offer,
  index,
  location,
}: {
  offer: SHOffer;
  index: number;
  location: string;
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    productClickedEvent,
    redirectRequestedEvent,
    proposalStartedEvent,
    elementClickedEvent,
    productViewedEvent,
  } = useTracking();
  const { user } = useUserContext();
  const [cookies] = useCookies(['app_version']);

  const userFormatted = {
    cpf: user?.document || '',
    fullName: user?.full_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    birthdate: user?.birthdate || '',
  };

  const extraFunctions = {
    beforeSendProposal: () => {
      const offerPartner = offer?.generalInfo?.partner;
      const isACOffer = offerPartner === 'acordocerto';

      if (isACOffer) {
        sendEventProductClicked(RouteMap.MyDebts);
        elementClickedEvent({
          elementType: 'button',
          location: pathname,
          name: 'Modal AC SH - Conferir se tenho dívidas',
        });
        return navigate(RouteMap.MyDebts);
      }
    },
    afterSendProposal: (fireboltId: string) => {},
    beforeRedirect: async (fireboltId: string, url: string) => {
      redirectRequestedEvent({
        fireboltId,
        offerSlug: offer?.content?.slug,
        originPath: pathname,
        redirectURL: url,
      });
      sendEventProductClicked(url);
      elementClickedEvent({
        elementType: 'button',
        location: pathname,
        name: `${location} - ${offer?.content?.button_text || ''}`,
      });
      await sendEventProposalStarted(url, fireboltId);
      await delay(200);
      if (isUserFromWebView() && cookies.app_version) return redirectInApp(url);
    },
    afterRedirect: (fireboltId: string, url: string) => {},
  };

  async function sendEventProposalStarted(
    redirectUrl: string,
    fireboltId: string
  ) {
    if (offer?.content?.full_funnel) return;

    const payload = {
      initialSlug: offer?.content?.slug,
      finalSlug: offer?.content?.slug,
      category: offer?.generalInfo?.category,
      redirectUrl,
      fireboltId,
    };
    await proposalStartedEvent(payload);
  }

  function sendEventProductClicked(url: string) {
    const productClickedEventPayload = {
      product: {
        brand: offer?.generalInfo?.partner,
        category: offer?.generalInfo?.category,
        location: location,
        name: offer?.content?.name,
        position: index,
        productId: offer?.content?.slug,
        text: offer?.content?.title,
        variant: offer?.content?.variant,
        url,
      },
    };
    productClickedEvent(productClickedEventPayload);
  }

  function sendProductViewedEvent() {
    const productViewedEventPayload = {
      brand: offer?.generalInfo.partner,
      category: offer?.generalInfo.category,
      location: location,
      name: offer?.metadata.name,
      position: index,
      productId: offer?.generalInfo.slug,
      text: offer?.content?.title,
      variant: offer?.content?.variant,
      partner: offer?.generalInfo.partner,
      price: Number(offer?.generalInfo.limit) || 0,
    };

    productViewedEvent(productViewedEventPayload);
  }

  async function handleDetailRedirect() {
    elementClickedEvent({
      elementType: 'card/button',
      name: offer.content.slug,
      location,
      text: 'Ver mais detalhes',
      position: `${index}`,
    });

    const detailUrl = RouteMap.ProductDetail.replace(
      ':slug',
      offer.content.slug
    );
    await delay(150);
    return navigate(detailUrl);
  }

  return {
    userFormatted,
    extraFunctions,
    sendProductViewedEvent,
    handleDetailRedirect,
  };
}
