import { useEffect, useState } from 'react';

import { SHOffer } from '@libs/creditkit';

import { useUserContext } from '@common/contexts/UserContext';
import { useSortingHat } from '@common/hooks/useSortingHat';
import { useTracking } from '@common/hooks/useTracking';
import { ModalDataEventProp } from '@common/hooks/useTracking/types';

export const useHubModal = () => {
  const { user } = useUserContext();
  const { shDecisionList } = useSortingHat();
  const { elementClickedEvent, modalViewedEvent } = useTracking();

  const [offers, setOffers] = useState<SHOffer[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const locationName = 'popup-ofertas';

  const username = user?.full_name.split(' ')[0] || null;
  const title = username
    ? `${username}, temos ofertas selecionadas para você`
    : 'Temos ofertas selecionadas para você';

  useEffect(() => {
    if (shDecisionList && shDecisionList.length > 0) {
      if (!firstRender) return;
      setFirstRender(false);
      mountComponent(shDecisionList);
    }
  }, [shDecisionList, firstRender]);

  function mountComponent(offerList: SHOffer[]) {
    const twoFirstOffers = offerList.slice(0, 2);
    setOffers(twoFirstOffers);
    const modalData = twoFirstOffers.map(
      (offer) =>
        ({
          cardSlug: offer.generalInfo.slug,
          partner: offer.generalInfo.partner,
        } as ModalDataEventProp)
    );

    setShowModal(true);
    modalViewedEvent({ modalData, location: locationName });
  }

  function handleClose() {
    elementClickedEvent({
      elementType: 'close',
      location: window.location.pathname,
      name: `${locationName} - Close`,
    });
    setShowModal(false);
  }

  return { offers, showModal, handleClose, title };
};
