import { useNavigate } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { useUserContext } from '@common/contexts/UserContext';
import { useEffect, useRef, useState } from 'react';
import { useApiScore } from '@tally/apis/score/useApiScore';
import { Notification } from '@tally/types';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { useRedline } from '@libs/redline';
import useConfirmDialog from '@common/components/ConfirmDialog/hook';
import { getFromStorage, removeFromStorage } from '@common/utils/handleStorage';
import { StorageMap } from '@common/constants/StorageMap';
import { MatchMakerCache } from '@hub/types/matchmaker';
import { useDrawer } from '@consumidor-positivo/aurora';
import { usePrivatePagesStates } from './data';

export const useLoggedInHeader = () => {
  const navigate = useNavigate();
  const { signOut } = useUserContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [noReads, setNoReads] = useState(false);
  const { track: trackRedline } = useRedline();
  const { confirm, ConfirmDialog } = useConfirmDialog();
  const { notificationRequest, notificationMarkAsRead, notificationDelete } =
    useApiScore();

  const { drawerOpen, handleOpenDrawer } = useDrawer({
    menu: false,
    notification: false,
  });

  const { dataSourceNavbar, dataSourceNavbarVertical } =
    usePrivatePagesStates();

  useEffect(() => {
    getNotifications();
  }, []);

  const isFirstTime = useRef(true);

  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }
    noReadsCount();
  }, [notifications]);

  useEffect(() => {
    if (drawerOpen.notification) {
      trackRedline.userTracking.elementViewed({
        location: window.location.pathname,
        elementType: 'content',
        name: 'notifications',
        text: 'visualizou as notificações',
        data: notifications,
      });
    }
  }, [drawerOpen.notification]);

  async function getNotifications() {
    try {
      const { data } = await notificationRequest.send();
      setNotifications(data || []);
    } catch (error) {
      handleErrorLog(error);
    }
  }

  function noReadsCount() {
    const noReadsCount = notifications.filter(
      (notification) => notification.read === false
    );

    if (window.isNativeApp) {
      const message = {
        action: 'syncNotificationsBadge',
        payload: { count: noReadsCount.length },
      };
      const stringifiedMessage = JSON.stringify(message);
      window.ReactNativeWebView.postMessage(stringifiedMessage);
    }

    setNoReads(noReadsCount.length > 0);
  }

  async function markAsReadNotification(notification: Notification) {
    const uuid = notification.uuid;

    if (notification.read === true) return;

    try {
      await notificationMarkAsRead.send({ uuid });
      setNotifications((prev) =>
        prev.map((n) => (n.uuid === uuid ? (n.read = true) : n, n))
      );
    } catch (error) {
      handleErrorLog(error);
    }
  }

  async function markAllAsReadNotification() {
    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      name: 'notifications',
      text: 'marcou todas como lidas',
      data: notifications,
    });

    if (!noReads) return;
    try {
      await notificationMarkAsRead.send();
      setNotifications((prev) => prev.map((n) => ((n.read = true), n)));
    } catch (error) {
      handleErrorLog(error);
    }
  }

  function formatDate(date: string) {
    const dateObject = new Date(date);
    const day = dateObject.toLocaleDateString('pt-BR', {
      day: '2-digit',
      timeZone: 'UTC',
    });
    const month = dateObject.toLocaleDateString('pt-BR', {
      month: 'short',
      timeZone: 'UTC',
    });

    return `${day}/${month}`;
  }

  function firstName(name: string) {
    const splitName = name.split(' ');
    return splitName[0];
  }

  async function deleteNotification(notification: Notification) {
    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      name: notification.title,
      uuid: notification.uuid,
      category: notification.category,
      text: `excluiu a notificação`,
      path: notification.path || '',
    });
    try {
      await notificationDelete.send({ uuid: notification.uuid });
      setNotifications((prev) =>
        prev.filter((n) => n.uuid !== notification.uuid)
      );
    } catch (error) {
      handleErrorLog(error);
    }
  }

  async function handleNavigateNotification(notification: Notification) {
    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      name: notification.title,
      uuid: notification.uuid,
      category: notification.category,
      text: `clicou na notificação`,
      path: notification.path || '',
    });

    await markAsReadNotification(notification);

    if (notification.category === 'generic' && !notification.path) return;

    const redirectMap = {
      score: RouteMap.ObjectivesHistoric,
      objective: RouteMap.Objectives,
      'negative-debts': RouteMap.NegativeDebts,
      lookout: RouteMap.CpfLookout,
      debts: RouteMap.MyDebts,
      offers: RouteMap.Offer,
    };

    notification.category === 'generic'
      ? window.open(notification.path, '_blank')
      : navigate(redirectMap[notification.category]);
  }

  async function handleNavigate(slug: RouteMap, title: string) {
    if (slug === RouteMap.MatchMaker) {
      const wasMatchMakerData = await getFromStorage<MatchMakerCache>(
        StorageMap.MatchMaker
      );
      if (wasMatchMakerData?.step_name === 'annuity') {
        handleOpenDrawer('menu');
        const result = await confirm({
          title: 'Match Positivo',
          message:
            'Você gostaria de refazer o quiz para ver novos resultados de cartões de crédito ou ver seu último resultado?',
          buttonTrue: 'Refazer quiz',
          buttonFalse: 'Ver resultado anterior',
        });

        if (result) {
          removeFromStorage(StorageMap.MatchMaker);
        }

        trackRedline.userTracking.elementClicked({
          elementType: 'button',
          name: 'match-maker-clean',
          location: window.location.pathname,
          text: result ? 'Refazer quiz' : 'Ver resultado anterior',
        });
      }
    }
    trackRedline.userTracking.elementClicked({
      elementType: 'button/text',
      name: slug,
      text: title,
      location: 'navbar',
    });

    if (slug === '/minhas-dividas' || slug === '/meus-acordos') {
      return (window.location.href = slug);
    }

    slug === RouteMap.Logout ? signOut(true) : navigate(slug);
  }

  return {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    drawerOpen,
    notifications,
    ConfirmDialog,
    formatDate,
    firstName,
    handleNavigate,
    handleOpenDrawer,
    handleNavigateNotification,
    markAllAsReadNotification,
    deleteNotification,
  };
};
