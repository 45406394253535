import Denakop from '@common/components/Denakop';

import { LoggedInHeader } from '@common/components/LoggedInHeader';
import { LoggedOutHeader } from '@common/components/LoggedOutHeader';
import { Conditional } from '@consumidor-positivo/aurora';
import { Footer } from '@common/components/Footer';

import { useUserContext } from '@common/contexts/UserContext';
import { useSortingHat } from '@common/hooks/useSortingHat';
import { RouteMap } from '@common/constants/RouteMap';

import { useEffect } from 'react';

import { AppNavButtons } from '@common/pages/Dashboard/dataNavigation';
import { AppNavigation } from '@common/components/AppNavigation';
import { useDashboard } from '@common/pages/Dashboard/hook';

import 'iq-blueberry/src/core/components/legacy/FooterClassic.styl';
import './styles.scss';

interface IPrivateDefaultProps {
  children: React.ReactNode;
  hideFooter?: boolean;
}

export const PrivateDefaultTemplate: React.FC<IPrivateDefaultProps> = ({
  children,
  hideFooter = false,
}) => {
  const { user, getUser } = useUserContext();
  const { variantControl } = useDashboard();
  useSortingHat({ immediate: true, decideDelayInSeconds: 2 });

  useEffect(() => {
    getUser();
  }, []);

  const denakopUnderBannerPages = [
    RouteMap.Objectives,
    RouteMap.MyDebts,
    RouteMap.NegativeDebts,
    RouteMap.BillsAndPayments,
    RouteMap.ObjectiveDetail,
    RouteMap.ObjectivesHistoric,
    RouteMap.ObjectivesProgress,
    RouteMap.CpfLookout,
  ];

  const createSlugFromPathname = (pathname: string) => {
    return pathname.replace(/^\//, '').split('/').join('-');
  };

  return (
    <>
      <Conditional
        condition={!!user}
        renderIf={<LoggedInHeader username={user?.full_name} />}
        renderElse={<LoggedOutHeader />}
      />

      <main className="private-default-template">
        {children}

        <Conditional
          condition={variantControl.isAppNavigation}
          renderIf={<AppNavigation buttons={AppNavButtons} />}
        />
      </main>

      <Conditional condition={!hideFooter} renderIf={<Footer />} />

      <Conditional
        condition={denakopUnderBannerPages.includes(
          location.pathname as RouteMap
        )}
        renderIf={
          <Denakop
            page={`${createSlugFromPathname(location.pathname)}-underbanner`}
            id="1"
            display={false}
          />
        }
      />
    </>
  );
};
