import {
  IconCoin,
  IconGiveMoney,
  IconVelocimeter,
  IconId,
  IconUser,
  IconHelpCircle,
} from '@consumidor-positivo/aurora';
import isDesktop from '@libs/utils/helpers/isDesktop';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';
import { useRenegAC } from '@common/hooks/useRenegAc';
import { useTracking } from '@common/hooks/useTracking';

export const usePrivatePagesStates = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { elementClickedEvent } = useTracking();
  const { enableRenegAC } = useRenegAC();

  const handleNavigate = (slug: RouteMap, title: string) => {
    elementClickedEvent({
      elementType: 'button/text',
      location: location.pathname,
      position: 'navbar',
      name: slug,
      text: title,
    });

    if (slug === RouteMap.Help) {
      window.open('https://ajuda.consumidorpositivo.com.br/');
    }

    navigate(slug);
  };

  const dataSourceNavbar = [
    {
      name: 'Score',
      dropdown: [
        {
          name: 'Minha pontuação',
          onClick: () => handleNavigate(RouteMap.MyScore, 'Minha pontuação'),
          active: location.pathname === RouteMap.MyScore,
        },
        {
          name: 'Aumentar meu Score',
          onClick: () =>
            handleNavigate(RouteMap.Objectives, 'Aumentar meu Score'),
          active: location.pathname === RouteMap.Objectives,
        },
        {
          name: 'Histórico de Score',
          onClick: () =>
            handleNavigate(RouteMap.ObjectivesHistoric, 'Histórico de Score'),
          active: location.pathname === RouteMap.ObjectivesHistoric,
        },
        {
          name: 'Meu progresso',
          onClick: () =>
            handleNavigate(RouteMap.ObjectivesProgress, 'Meu progresso'),
          active: location.pathname === RouteMap.ObjectivesProgress,
        },
      ],
    },
    {
      name: 'Crédito',
      dropdown: [
        {
          name: 'Minhas ofertas',
          onClick: () => handleNavigate(RouteMap.MyOffers, 'Minhas ofertas'),
          active: location.pathname === RouteMap.MyOffers,
        },
        {
          name: 'Todas ofertas',
          onClick: () => handleNavigate(RouteMap.Offer, 'Todas ofertas'),
          active: location.pathname === RouteMap.Offer,
        },
        {
          name: 'Match Positivo',
          onClick: () => handleNavigate(RouteMap.MatchMaker, 'Match Positivo'),
          active: location.pathname === RouteMap.MatchMaker,
        },
      ],
    },
    {
      name: 'Meu CPF',
      dropdown: [
        {
          name: 'Minhas dívidas',
          onClick: () => handleNavigate(RouteMap.MyCpf, 'Minhas dívidas'),
          active: location.pathname === RouteMap.MyCpf,
        },
        ...(enableRenegAC
          ? [
              {
                name: 'Meu acordos',
                onClick: () =>
                  handleNavigate(RouteMap.MyAgreementsAC, 'Meus acordos'),
                active: location.pathname === RouteMap.MyAgreementsAC,
              },
            ]
          : []),
        {
          name: 'Dívidas negativadas',
          onClick: () =>
            handleNavigate(RouteMap.NegativeDebts, 'Dívidas negativadas'),
          active: location.pathname === RouteMap.NegativeDebts,
        },
        {
          name: 'Alerta CPF',
          onClick: () => handleNavigate(RouteMap.MonitoringCenter, 'Meu CPF'),
          active: location.pathname === RouteMap.MonitoringCenter,
        },
        {
          name: 'Consultas em meu CPF',
          onClick: () =>
            handleNavigate(RouteMap.CpfLookout, 'Consultas em meu CPF'),
          active: location.pathname === RouteMap.CpfLookout,
        },
        {
          name: 'Contas e pagamentos',
          onClick: () =>
            handleNavigate(RouteMap.BillsAndPayments, 'Contas e pagamentos'),
          active: location.pathname === RouteMap.BillsAndPayments,
        },
      ],
    },
    {
      name: 'Central de Ajuda',
      onClick: () => handleNavigate(RouteMap.Help, 'Central de Ajuda'),
      active: false,
    },
  ];

  const dataSourceNavbarVertical = [
    {
      name: 'Dívidas',
      Icon: <IconCoin />,
      dropdown: [
        {
          name: 'Minhas dívidas',
          onClick: () => handleNavigate(RouteMap.MyDebts, 'Minhas dívidas'),
          active: location.pathname === RouteMap.MyDebts,
        },
        ...(enableRenegAC
          ? [
              {
                name: 'Meu acordos',
                onClick: () =>
                  handleNavigate(RouteMap.MyAgreementsAC, 'Meus acordos'),
                active: location.pathname === RouteMap.MyAgreementsAC,
              },
            ]
          : []),
        {
          name: 'Dívidas negativadas',
          onClick: () =>
            handleNavigate(RouteMap.NegativeDebts, 'Dívidas negativadas'),
          active: location.pathname === RouteMap.NegativeDebts,
        },
      ],
    },
    {
      name: 'Crédito',
      Icon: <IconGiveMoney />,
      dropdown: [
        {
          name: 'Minhas ofertas',
          onClick: () => handleNavigate(RouteMap.MyOffers, 'Minhas ofertas'),
          active: location.pathname === RouteMap.MyOffers,
        },
        {
          name: 'Todas ofertas',
          onClick: () => handleNavigate(RouteMap.Offer, 'Todas ofertas'),
          active: location.pathname === RouteMap.Offer,
        },
        {
          name: 'Match Positivo',
          onClick: () => handleNavigate(RouteMap.MatchMaker, 'Match Positivo'),
          active: location.pathname === RouteMap.MatchMaker,
        },
      ],
    },
    {
      name: 'Score',
      Icon: <IconVelocimeter />,
      dropdown: [
        {
          name: 'Aumentar meu Score',
          onClick: () =>
            handleNavigate(RouteMap.Objectives, 'Aumentar meu Score'),
          active: location.pathname === RouteMap.Objectives,
        },
        {
          name: 'Histórico de Score',
          onClick: () =>
            handleNavigate(RouteMap.ObjectivesHistoric, 'Histórico de Score'),
          active: location.pathname === RouteMap.ObjectivesHistoric,
        },
        {
          name: 'Meu progresso',
          onClick: () =>
            handleNavigate(RouteMap.ObjectivesProgress, 'Meu progresso'),
          active: location.pathname === RouteMap.ObjectivesProgress,
        },
      ],
    },
    {
      name: 'Meu CPF',
      Icon: <IconId />,
      dropdown: [
        {
          name: 'Alerta CPF',
          onClick: () =>
            handleNavigate(RouteMap.MonitoringCenter, 'Alerta CPF'),
          active: location.pathname === RouteMap.MonitoringCenter,
        },
        {
          name: 'Consultas em meu CPF',
          onClick: () =>
            handleNavigate(RouteMap.CpfLookout, 'Consultas em meu CPF'),
          active: location.pathname === RouteMap.CpfLookout,
        },
        {
          name: 'Contas e pagamentos',
          onClick: () =>
            handleNavigate(RouteMap.BillsAndPayments, 'Contas e pagamentos'),
          active: location.pathname === RouteMap.BillsAndPayments,
        },
      ],
    },
    {
      name: 'Minha conta',
      Icon: <IconUser />,
      onClick: () => handleNavigate(RouteMap.UserProfile, 'Minha conta'),
      active: location.pathname === RouteMap.UserProfile,
    },
    {
      name: 'Central de Ajuda',
      Icon: <IconHelpCircle />,
      onClick: () => handleNavigate(RouteMap.Help, 'Central de Ajuda'),
      active: false,
    },
  ].filter((item) => (isDesktop() ? item.name.includes('Minha conta') : item));

  return {
    dataSourceNavbar,
    dataSourceNavbarVertical,
  };
};
