import {
  Header,
  LogoPrimaryCP,
  LogoBadgetCP,
  Drawer,
  ProfileNav,
  NavbarVertical,
  Button,
  Text,
  NotificationsBar,
} from '@consumidor-positivo/aurora';

import { RouteMap } from '@common/constants/RouteMap';
import { AppReview } from '../components/AppReview';
import { createPortal } from 'react-dom';
import { notifyIcons } from './icons';

import { useLoggedInHeader } from './hook';
import './styles.scss';

interface LoggedInHeaderProps {
  username?: string;
}

export const LoggedInHeaderV2: React.FC<LoggedInHeaderProps> = ({
  username,
}) => {
  const {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    drawerOpen,
    notifications,
    ConfirmDialog,
    formatDate,
    firstName,
    handleOpenDrawer,
    handleNavigate,
    handleNavigateNotification,
    deleteNotification,
  } = useLoggedInHeader();

  const news = notifications.filter(
    (notification) => !notification.read
  ) as unknown as Notification[];
  const olds = notifications.filter(
    (notification) => notification.read
  ) as unknown as Notification[];

  const renderNotificationList = (
    title: string,
    notifications: Notification[]
  ) => (
    <NotificationsBar.List
      title={title}
      dataSource={notifications.map((item) => ({
        ...item,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createdAt: (item as any).date || '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: () => handleNavigateNotification(item as any),
      }))}
      renderItem={(item) => (
        <NotificationsBar.Link
          key={`notification-item-${item.title}`}
          title={item.title}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Icon={notifyIcons[(item as any).category as keyof typeof notifyIcons]}
          createdAt={formatDate(item.createdAt || '')}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onDelete={() => deleteNotification(item as any)}
          onClick={() => {
            handleOpenDrawer('notification');
            item.onClick?.();
          }}
        />
      )}
    />
  );

  return (
    <>
      <div className="logged-in-header">
        <Header.Root>
          <Header.Button
            onClick={() => handleNavigate(RouteMap.Dashboard, 'Dashboard')}
          >
            <Header.Logo
              renderDesktop={<LogoPrimaryCP />}
              renderMobile={<LogoBadgetCP />}
            />
          </Header.Button>
          <Header.Navigation>
            <Header.Navbar
              data={dataSourceNavbar}
              renderItem={(item) => (
                <Header.NavbarLink
                  key={item.name}
                  active={item.active}
                  name={item.name}
                  dropdown={item.dropdown}
                  onClick={item.onClick}
                />
              )}
            />
            <Header.Actions divider>
              <Header.Profile
                onClickMenu={() => handleOpenDrawer('menu')}
                onClickNotifications={() => handleOpenDrawer('notification')}
                fullName={username || ''}
              />
            </Header.Actions>
          </Header.Navigation>

          <Drawer
            isOpen={drawerOpen.menu}
            handleOpen={() => handleOpenDrawer('menu')}
            renderHeader={
              <ProfileNav
                name={firstName(username || '')}
                fullName={username || ''}
              />
            }
            renderContent={
              <NavbarVertical
                data={dataSourceNavbarVertical}
                renderItem={(link) => {
                  return (
                    <NavbarVertical.Link
                      key={link.name}
                      active={link.active}
                      Icon={link.Icon}
                      name={link.name}
                      dropdown={link.dropdown}
                      onClick={() => {
                        handleOpenDrawer('menu');
                        link.onClick?.();
                      }}
                    />
                  );
                }}
                renderActions={() => (
                  <div className="logged-in-header__actions">
                    <AppReview />

                    <Button
                      type="outlined"
                      expand="x"
                      onClick={() => handleNavigate(RouteMap.Logout, 'Sair')}
                    >
                      Sair
                    </Button>
                  </div>
                )}
              />
            }
          />
          <Drawer
            isOpen={drawerOpen.notification}
            handleOpen={() => handleOpenDrawer('notification')}
            renderHeader={
              <Text as="h3" variant="heading-small" weight="bold">
                Notificações
              </Text>
            }
            renderContent={
              <NotificationsBar.Root
                renderRecents={() => renderNotificationList('Recentes', news)}
                renderOlds={() => renderNotificationList('Antigas', olds)}
              />
            }
          />
        </Header.Root>
      </div>

      {createPortal(ConfirmDialog, document.body)}
    </>
  );
};
