import { OfferCard } from '@libs/creditkit';
import { OfferProps } from '@hub/types';
import { useOffer } from '@hub/hooks/useOffer';

export const MainOfferCard = ({
  offer,
  index,
  location,
  smallCard = false,
}: OfferProps) => {
  const {
    userFormatted,
    extraFunctions,
    handleDetailRedirect,
    sendProductViewedEvent,
  } = useOffer({ offer, index, location });

  return (
    <>
      <OfferCard
        offer={offer}
        user={userFormatted}
        smallCard={smallCard}
        onClickOffer={extraFunctions}
        onClickDetailOffer={() => handleDetailRedirect()}
        onViewOffer={sendProductViewedEvent}
      />

    </>
  );
};
