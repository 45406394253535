import { Text } from '@consumidor-positivo/aurora';

import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';

import { Modal } from '@common/components/Modal';

import { MainOfferCard } from '../MainOfferCard';
import { useHubModal } from './hook';
import './styles.scss';

export const HubModal: React.FC = () => {
  const { handleClose, showModal, offers, title } = useHubModal();

  const { isMobile } = useDeviceSize();

  return (
    <Modal
      desktop="center"
      mobile="bottom"
      showModal={showModal}
      hasCloseButton
      onClose={handleClose}
      className="hub-modal"
    >
      <div className="hub-modal__content">
        <div className="hub-modal__title">
          <Text
            as="h2"
            variant="heading-small"
            variantDesk="heading-large"
            weight='bold'
          >
            {title}
          </Text>
        </div>
        <div className="hub-modal__cards">
          {offers.map((offer, index) => (
            <div
              className="hub-modal__card"
              key={`hub-modal-${offer.content.slug}`}
            >
              <MainOfferCard
                offer={offer}
                index={index}
                location="popup-ofertas"
                smallCard={isMobile}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
