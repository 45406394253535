import { RouteMap } from '@common/constants/RouteMap';
import {
  IconGiveMoney,
  IconId,
  IconVelocimeter,
} from '@consumidor-positivo/aurora';

export const AppNavButtons = [
  { label: 'Score', path: RouteMap.MyScore, icon: <IconVelocimeter /> },
  { label: 'Crédito', path: RouteMap.MyOffers, icon: <IconGiveMoney /> },
  { label: 'Meu CPF', path: RouteMap.MyCpf, icon: <IconId /> },
];
