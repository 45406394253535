export function handleFullFunnelRedirect(searchParams: string) {
  const params = new URLSearchParams(searchParams);
  if (!params.has('opf')) {
    return {
      fullFunnelCheckURL: '',
      isFullFunnelOrigin: false,
      partnerSlug: '',
    };
  }

  const partnerSlug = params.get('opf');

  params.delete('opf');
  params.delete('c'); // AC PARAM
  return {
    fullFunnelCheckURL: `/analisando-oferta${
      !!params ? `?${params.toString()}` : ''
    }`,
    isFullFunnelOrigin: true,
    partnerSlug,
  };
}
