import { useDogxContext } from '@libs/dogx';

export const useDashboard = () => {
  const { getExperimentVariant } = useDogxContext();
  const variant = getExperimentVariant('dx-dashboard-navigation');

  const variantControl = {
    isControl: !variant || variant === 'control',
    isNewNavigation:
      variant === 'app-navigation' || variant === 'page-navigation',
    isPageNavigation: variant === 'page-navigation',
    isAppNavigation: variant === 'app-navigation',
  };

  return {
    variantControl,
  };
};
